/* ./src/index.css */
@tailwind base;
@tailwind components;

@tailwind utilities;

@import 'react-toastify/dist/ReactToastify.css';

body {
  font-family: "Museo Sans", sans-serif;
  background-color: #edf1f2;
  color: #36464d;
}

#root {
  height: 100vh;
}
